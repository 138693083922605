import React, { memo } from 'react';
import PropTypes from 'prop-types';

import CollectionProductsImagesSlider from '../Collection/CollectionProductsImagesSlider';

const DynamicCollectionProductsImagesSlider = ({ blok }) => {
  return <CollectionProductsImagesSlider {...blok} />;
};

DynamicCollectionProductsImagesSlider.propTypes = {
  blok: PropTypes.shape({
    collection: PropTypes.object.isRequired,
    paintEntry: PropTypes.object,
    thumbnails: PropTypes.bool,
    navigation: PropTypes.bool,
    imageMatcher: PropTypes.string,
    product: PropTypes.object,
  }),
};

export default memo(DynamicCollectionProductsImagesSlider);
