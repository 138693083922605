import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box } from 'grommet';
import { get, map, flatten } from '../../lib/nodash';

import MultiImageContentModule from '../DynamicPage/MultiImageContentModule';
import { findImages } from '../../lib/findImage';

const CollectionProductsImagesSlider = ({
  collection,
  paintEntry,
  thumbnails,
  navigation,
  imageMatcher,
  paginationType,
  product,
}) => {
  const productImages = paintEntry
    ? (get('content.images', paintEntry) || []).map((x) => ({
        originalSrc: x.filename,
        alt: x.alt,
        title: x.title,
      }))
    : flatten(
        map(
          'images',
          get('products', collection).sort((a, b, i) => {
            return a.shopifyId === product.shopifyId
              ? -1
              : collection.products.indexOf(i);
          })
        )
      );

  const filteredProductImages = imageMatcher
    ? findImages(productImages, imageMatcher)
    : productImages;

  const images = map(
    (x) => ({
      image: x,
      fit: 'cover',
      fill: true,
      options: { q: 75, w: 500, auto: ['compress', 'format'] },
      cs: 'srgb',
      alt: `${collection.title}${x.altText ? ` - ${x.altText}` : ''}`,
      srcSetOptions: {
        q: 75,
        w: 500,
        auto: ['compress', 'format'],
      },
    }),
    filteredProductImages
  );

  return (
    <Box fill>
      <MultiImageContentModule
        images={images}
        paginationType={paginationT}
        navigation={navigation}
        swiperParams={{
          spaceBetween: 0,
          slidesPerView: 1,
          autoHeight: false,
          lazy: {
            loadPrevNext: true,
          },
          navigation: true,
        }}
      />
    </Box>
  );
};

CollectionProductsImagesSlider.propTypes = {
  collection: PropTypes.object.isRequired,
  paintEntry: PropTypes.object,
  thumbnails: PropTypes.bool,
  navigation: PropTypes.bool,
  imageMatcher: PropTypes.string,
  product: PropTypes.object,
};

export default memo(CollectionProductsImagesSlider);
